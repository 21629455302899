import React from 'react';

import MassTransfer from '../../components/views/items/mass-transfer'
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <MassTransfer />
        </LayoutLoggedIn>
    );
}